<template>
    <div>
        <div class="screenWidth">
            <currentLocation :sibItem="sibItem"></currentLocation>
            <div class="name">
                <h1>NEWS & EVENTS</h1>
                <div>Construction chemical knowledge and information</div>
                <div class="details_box_left_pb">
                    <el-select @change="selectclick" v-model="value" placeholder="请选择">
                        <el-option v-for="item in news_type" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="details_box">
                <!-- 平板显示 -->
                <div class="details_box_left">
                    <div class="Product_list_left_one">
                        News category
                    </div>
                    <div class="Product_list_left_one_list">
                        <ul>
                            <li @click="newsclik(item.id)" v-for="(item, index) in news_type" :key="index">
                                <div class="fha"><span :style="{ 'background': id == item.id ? '#BA0003' : '' }"></span>
                                </div>
                                <div :class="{ 'text': true, 'activebk': id == item.id }">{{ item.name }}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="details_box_right">
                    <div class="news_box">
                        <div @click="todetail(item.id)" class="news_list" v-for="(item, index) in news_list"
                            :key="index">
                            <div class="news_left_img">
                                <img class="news_left" :src="item.image" alt="">
                            </div>
                            <div class="news_right">
                                <div class="title">{{ item.title }}</div>
                                <div class="timers">
                                    <img src="../assets/image/rls.png" alt="">
                                    <div>{{ item.createtime }}</div>
                                </div>
                                <div class="content_title">{{ item.descript }}</div>
                            </div>
                        </div>
                        <div class="kong" v-if="news_list.length < 1">
                            <img src="../assets/image/kong.png" alt="">
                            <div>No Data</div>
                        </div>
                        <div class="yema" style="text-align: center;margin-top: 60px;" v-if="totalPages > 1">
                            <el-pagination @prev-click="Previous" @next-click="NextPage"
                                @current-change="handleCurrentChange" background layout="prev, pager, next"
                                :page-count="totalPages">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            sibItem: [
                {
                    title: "Home",
                    path: "/",
                },
                {
                    title: "News",
                    path: "",
                },
            ],
            currentPage: 1, // 当前页数，默认为第一页
            pageSize: 12, // 每页显示的条目数
            totalPages: 0,//总页码
            news_type: [],//新闻分类
            news_list: [],//新闻列表
            id: '',
            value: '',
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.value = this.$route.query.name
        this.getnews()
    },
    methods: {
        selectclick(e) {
            this.id = this.value
            this.getnewList()
        },
        /**
         * 分类点击
         * 刘义博
         * 2024-7-20
         */
        newsclik(id) {
            this.id = id
            this.currentPage = 1
            this.getnewList()
        },
        /**
         * 获取新闻列表
         * 刘义博
         * 2024-7-20
         */
        getnewList() {
            this.$request({
                url: "/Index/newsList",
                data: {
                    type_id: this.id,
                    page: this.currentPage
                }
            }).then((res) => {
                console.log('新闻列表', res.data);
                this.news_list = res.data.news_list
                this.totalPages = Math.ceil(res.data.news_count / 12);
            });
        },
        /**
         * 获取新闻分类
         * 刘义博
         * 2024-7-20
         */
        getnews() {
            this.$request({
                url: "/Index/newsType",
            }).then((res) => {
                this.news_type = res.data.news_type
                this.getnewList()
                console.log("新闻分类", res);
            });
        },
        todetail(id) {
            this.$router.push({
                path: '/newsDetail',
                query: { id: id }
            })
        },
        // 上一页
        Previous(e) {
            this.currentPage = e
            return
            // this.getnewslist(localStorage.getItem('Language'))
        },
        //下一页
        NextPage(e) {
            this.currentPage = e
            return
            // this.getnewslist(localStorage.getItem('Language'))
        },
        // 处理页码变化事件
        handleCurrentChange(page) {
            this.currentPage = page;
            // this.getnewslist(localStorage.getItem('Language'))
            this.getnewList()
        },
    },
    watch: {},
    computed: {},
    filters: {},
    components: {}
}
</script>
<style>
/* 移动端分类css */
.el-select-dropdown__item.selected {
    color: #BA0003;
}

.el-select .el-input.is-focus .el-input__inner {
    width: 70vw !important;
    border-color: #BA0003;
    background: #BA0003;
    color: #fff;
}

.el-select:hover .el-input__inner,
.el-input__inner {
    width: 70vw !important;
    border-color: #BA0003;
    background: #BA0003;
    color: #fff;
}

.el-select .el-input .el-select__caret {
    color: #fff;
}

select{
    width: 100%;
    background: red;
    width: 80%;
    padding: 100px 50px;
    border-radius: 10px;
    border: 0;
    box-sizing: border-box;
}
</style>
<style scoped lang="less">
@media (min-width: 1px) and (max-width: 767px) {
    .name {
        margin: 200px 0 50px 0!important;

        h1 {
            font-size: 110px !important;
        }

        div {
            font-size: 75px !important;
            margin-bottom: 50px !important;
        }
    }

    .details_box_left {
        display: none !important;
    }

    .details_box_left_pb {
        display: block !important;
    }


    .details_box {
        display: flex;
        flex-direction: column;
    }

    .EVENTS {
        font-size: 100px !important;
    }

    .Construction {
        font-size: 80px !important;
        margin-bottom: 50px !important;
    }



    .details_box_right {
        width: 100% !important;
        margin-bottom: 120px !important;
    }

    .news_list {
        height: auto !important;
        display: flex;
        flex-direction: column;
        margin-bottom: 150px !important;
        padding: 50px !important;
        box-shadow: 0px 15px 40px 1px rgba(0, 0, 0, 0.2);
        border: 0 !important;

        .news_left_img {
            width: 100% !important;

            .news_left {
                width: 100% !important;
                min-height: 1500px !important;
            }

        }
    }

    .news_right {
        .title {
            font-size: 90px !important;
            text-align: justify;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5 !important;
            margin-top: 50px !important;
        }

        .timers {
            margin: 40px 0 !important;

            img {
                width: 72px !important;
                height: 72px !important;
                margin-right: 30px !important;
            }

            div {
                font-size: 75px !important;
                margin-left: 15px !important;
            }
        }



        .content_title {
            font-size: 75px !important;
            line-height: 120px !important;
            text-align: justify;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 8 !important;
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .EVENTS {
        font-size: 50px !important;
    }

    .Construction {
        font-size: 30px !important;
        margin-bottom: 50px !important;
    }

    .details_box_left {
        .Product_list_left_one {
            font-size: 40px !important;
            padding: 20px 25px !important;
        }

        .Product_list_left_one_list ul li {
            .text {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 25px !important;
                font-weight: bold;
            }

            .fha {
                width: 5%;
                height: 100%;
                display: flex;
                align-items: center;

                span {
                    display: block;
                    width: 10px !important;
                    height: 10px !important;
                    background: #222;
                    border-radius: 50%;
                }
            }
        }
    }

    .details_box_left_box {
        div {
            font-size: 50px !important;
            padding: 30px 30px !important;
        }
    }

    .news_list {
        height: 280px !important;
        margin-bottom: 80px !important;

        .news_left_img {
            width: 380px !important;
        }
    }

    .news_right {
        .title {
            font-size: 30px !important;
        }

        img {
            width: 35px !important;
            height: 38px !important;
        }

        .timers div {
            font-size: 28px !important;
            margin-left: 15px !important;
        }

        .content_title {
            font-size: 28px !important;
            line-height: 53px !important;
        }
    }
}

.name {
    margin-top: 30px;
    cursor: default;

    h1 {
        font-size: 25px;
        margin-bottom: 5px;
    }

    div {
        font-size: 14px;
        margin-bottom: 25px;
    }
}

.details_box_left_pb {
    display: none;
}

.screenWidth {
    padding-bottom: 100px;
}

.activebk {
    color: #BA0003 !important;
}

.EVENTS {
    font-size: 30px;
    color: #BA0003;
    margin: 20px 0;
}

.Construction {
    font-size: 20px;
    margin: 20px 0;
}

.details_box {
    background: #fff;
    display: flex;
    justify-content: space-between;

    .details_box_left {
        width: 25%;
        position: sticky;
        top: 0;
        left: 0;

        .Product_list_left_one {
            background: #BA0003;
            color: #fff;
            padding: 5px 25px;
            line-height: 64px;
            text-align: left;
            font-size: 28px;
            cursor: default;
            word-break: break-word !important;

        }

        .Product_list_left_one_list {
            border: 1px solid #dcdcdc;

            ul li:hover {
                .text {
                    color: #BA0003;
                }

                .fha span {
                    background: #BA0003;
                }
            }

            ul {
                margin: 0;
                padding: 10px 20px 10px 10px;
                cursor: pointer;



                li:last-child {
                    margin-bottom: 0 !important;
                }

                li {
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;

                    .text {
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 16px;
                        font-weight: bold;
                    }

                    .fha {
                        width: 5%;
                        height: 100%;
                        display: flex;
                        align-items: center;

                        span {
                            display: block;
                            width: 5px;
                            height: 5px;
                            background: #222;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }

    .details_box_right {
        width: 73%;
        min-height: 50vh;
        position: relative;

        .news_box {
            .news_list:hover {
                box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);

                .news_right .title {
                    color: #BA0003;
                }
            }

            .news_list {
                width: 100%;
                height: 170px;
                background: #fff;
                display: flex;
                margin-bottom: 30px;
                cursor: pointer !important;
                border: 1px solid #dcdcdc;
                padding: 10px;
                box-sizing: border-box;

                .news_left_img {
                    display: block;
                    width: 220px;
                    height: 100%;
                    margin-right: 20px;
                    overflow: hidden;

                    .news_left {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }



                .news_right {
                    width: 100%;
                    padding: 5px 22px 5px 0;

                    .title {
                        font-weight: bold;
                        font-size: 19px;
                        color: #222222;
                        text-align: justify;
                        word-wrap: break-word;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                    }




                    .timers {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;

                        img {
                            width: 15px;
                            height: 15px;
                        }

                        div {
                            font-size: 15px;
                            color: #666;
                            margin-left: 5px;
                        }
                    }

                    .content_title {
                        margin-top: 10px;
                        font-size: 15px;
                        color: #666;
                        text-align: justify;
                        word-wrap: break-word;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        line-height: 30px;
                    }

                }
            }
        }
    }
}
</style>